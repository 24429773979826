export const FEATURE_TOGGLE_REDPOINTS_ACTIVE = 'redpoints.active'
export const FEATURE_TOGGLE_USE_INTERACTIONS = 'useInteractions'
export const FEATURE_TOGGLE_REVIEWS_FROM_OTHER_LANGUAGES =
  'showReviewsFromOtherLanguages'
export const FEATURE_TOGGLE_SAE_CONVERSATIONAL_CHAT = 'saeConversationalChat'
export const FEATURE_TOGGLE_MEETERGO_SCHEDULING = 'useMeetergoScheduling'
export const FEATURE_TOGGLE_BONUS_LP_NEW_DESIGN = 'useErxBonusLpNewDesign'
export const FEATURE_TOGGLE_ONE_TIME_PASSWORD = 'oneTimePasswordLoginEnabled'
export const FEATURE_TOGGLE_USE_LEAFLETS = 'useLeaflets'
export const FEATURE_TOGGLE_FRIENDLY_CAPTCHA = 'friendlyCaptcha'
export const FEATURE_TOGGLE_VOLUME_BASED_PRICES =
  'useMarketplaceVolumeBasedPrices'

export const allowedPublicFeatureToggles = {
  [FEATURE_TOGGLE_BONUS_LP_NEW_DESIGN]: true,
  [FEATURE_TOGGLE_FRIENDLY_CAPTCHA]: true,
  [FEATURE_TOGGLE_MEETERGO_SCHEDULING]: true,
  [FEATURE_TOGGLE_ONE_TIME_PASSWORD]: true,
  [FEATURE_TOGGLE_REDPOINTS_ACTIVE]: true,
  [FEATURE_TOGGLE_SAE_CONVERSATIONAL_CHAT]: true,
  [FEATURE_TOGGLE_VOLUME_BASED_PRICES]: true,
}

/**
 * Please add feature toggles when added in Stupido to have correct
 * TypeScript check.
 *
 * Please remove feature toggle when it is removed from all tenants and envs
 */
export type PrivateFeatureToggles = Partial<{
  [FEATURE_TOGGLE_BONUS_LP_NEW_DESIGN]: true
  [FEATURE_TOGGLE_FRIENDLY_CAPTCHA]: boolean
  [FEATURE_TOGGLE_MEETERGO_SCHEDULING]: boolean
  [FEATURE_TOGGLE_ONE_TIME_PASSWORD]: boolean
  [FEATURE_TOGGLE_REDPOINTS_ACTIVE]: boolean
  [FEATURE_TOGGLE_REVIEWS_FROM_OTHER_LANGUAGES]: boolean
  [FEATURE_TOGGLE_SAE_CONVERSATIONAL_CHAT]: boolean
  [FEATURE_TOGGLE_USE_INTERACTIONS]: boolean
  [FEATURE_TOGGLE_USE_LEAFLETS]: boolean
  [FEATURE_TOGGLE_VOLUME_BASED_PRICES]: boolean
}>

export type PublicFeatureToggles = Pick<
  PrivateFeatureToggles,
  keyof typeof allowedPublicFeatureToggles
>
