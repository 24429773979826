'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'

/**
 * Will run a callback whenever searchParams or pathname changes.
 * Basically will run a callback when navigated to a new page.
 */
export const useRunCallbackWhenNavigated = (
  onPageChange: (options: {
    newPath: string | null
    previousPath: string | null
    previousSearchParams: string | null
    searchParams: string | null
  }) => void,
): void => {
  const pathname = usePathname()
  const previousPathnameRef = useRef(pathname)
  const searchParams = useSearchParams().toString()
  const previousSearchParamsRef = useRef(searchParams)

  useEffect(() => {
    // If the pathname has changed, call the callback function.
    if (
      previousPathnameRef.current !== pathname ||
      previousSearchParamsRef.current !== searchParams
    ) {
      onPageChange({
        newPath: pathname,
        previousPath: previousPathnameRef.current,
        previousSearchParams: previousSearchParamsRef.current,
        searchParams,
      })
      // new path and searchParams become previous
      previousPathnameRef.current = pathname
      previousSearchParamsRef.current = searchParams
    }
  }, [pathname, onPageChange, searchParams])
}
