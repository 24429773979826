import { clsx } from 'clsx'
import { type FC } from 'react'

import { type CmsIcons } from '@redteclab/api/clients/content-management-system'

import { HeaderInlineIcon } from '../HeaderInlineIcon'

import styles from './HeaderCategoriesMenuIconButton.module.css'

export const HeaderCategoriesMenuIconButton: FC<{
  icon: CmsIcons | undefined
  menuIsShown: boolean
  onToggleMenu: () => void
  title: string | undefined
}> = ({ icon, menuIsShown, onToggleMenu, title }) => {
  return (
    <button
      aria-controls="categories-menu"
      aria-haspopup="true"
      // we need z-index so the categories button will be above the loading indicator
      className={clsx(
        'relative z-10 flex h-12 items-center fill-dark-primary-max pr-3.5 font-medium text-dark-primary-max hover:fill-dark-hover-brand hover:text-dark-hover-brand',
        menuIsShown && styles['header-categories-menu-icon-button__arrow-up'],
      )}
      data-qa-id="form-categories"
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault()
          onToggleMenu()
        }
      }}
      role="menuitem"
      type="button"
    >
      <HeaderInlineIcon className="m-2.5 text-3xl" icon={icon} />
      {title}
    </button>
  )
}
