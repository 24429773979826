import { type FC } from 'react'

import { type CmsBlockFooterCategories } from '@redteclab/api/clients/content-management-system'

import { useExpaContext } from '../../../../../expa'
import { FooterLinkCategory } from '../FooterLinkCategory'

type FooterCategoriesSectionProps = {
  categories?: CmsBlockFooterCategories
}

export const FooterCategoriesSection: FC<FooterCategoriesSectionProps> = ({
  categories,
}) => {
  const expa = useExpaContext()

  if (!categories?.items.length) {
    return null
  }

  return (
    <div className="container hidden tablet:block tablet:pb-6 tablet:pt-14">
      <h2 className="mb-6 text-l font-medium">{categories.title}</h2>
      <div className="grid grid-cols-4 gap-x-2.5 gap-y-10">
        {categories.items.map((category) => (
          <FooterLinkCategory
            category={{
              items: category.items.filter(
                (item) =>
                  !item.cleansite?.some((cleansite) => cleansite === expa),
              ),
              title: category.title,
            }}
            key={category.title}
          />
        ))}
      </div>
    </div>
  )
}
