'use client'

import { type FC } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import { FooterLegalTextIframe } from './FotterLegalTextIframe'

interface FooterLegalTextProps {
  legalTextIframeSrc: string
}

export const FooterLegalText: FC<FooterLegalTextProps> = ({
  legalTextIframeSrc,
}) => {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
  })

  return (
    <div className="container flex justify-center py-5" ref={ref}>
      {isIntersecting ? (
        <FooterLegalTextIframe legalTextIframeSrc={legalTextIframeSrc} />
      ) : null}
    </div>
  )
}
