import { type LiteClient } from 'algoliasearch/lite'
import { useMemo, useRef } from 'react'

import { type HeaderSearchContextValue } from './components/types'

/**
 * The algolia component is loaded dynamically, so this will be used, to propagate search client, from dynamically,
 * loaded components to the context.
 */
export const useSearchClientControls =
  (): HeaderSearchContextValue['searchClientControls'] => {
    const searchClientRef = useRef<LiteClient | null>(null)
    const searchClientWithSessionStorageCacheRef = useRef<LiteClient | null>(
      null,
    )

    return useMemo((): HeaderSearchContextValue['searchClientControls'] => {
      return {
        getSearchClient: (): LiteClient => {
          const searchClient = searchClientRef.current

          if (!searchClient) {
            throw new Error('Search_client_is_not_initialized_but_accessed')
          }

          return searchClient
        },
        /**
         * important see the docs for the {@link algoliaCreate}
         */
        getSearchClientWithSingleQuerySessionStorageCache: (): LiteClient => {
          const searchClient = searchClientWithSessionStorageCacheRef.current
          if (!searchClient) {
            throw new Error('Search_client_is_not_initialized_but_accessed')
          }

          return searchClient
        },
        isSearchClientInitialized: () => Boolean(searchClientRef.current),

        isSearchClientWithSingleQuerySessionStorageCacheInitialized: () =>
          Boolean(searchClientWithSessionStorageCacheRef.current),
        setSearchClient: (searchClientInstance: LiteClient): void => {
          searchClientRef.current = searchClientInstance
        },
        setSearchClientWithSingleQuerySessionStorageCache: (
          clientInstance: LiteClient,
        ): void => {
          searchClientWithSessionStorageCacheRef.current = clientInstance
        },
      }
    }, [searchClientRef])
  }
