import { type JSX, useCallback, useEffect, useMemo, useRef } from 'react'
import { useSessionStorage } from 'usehooks-ts'

import { Dialog, DialogTitle } from 'base-ui'

import { useExpaContext } from '../../expa/components/ExpaProvider/ExpaContextProvider'
import {
  type OpenableControls,
  useOpenableControls,
} from '../../generic-hooks/useOpenableControls/useOpenableControls'
import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { gtmDataLayerPush } from '../../gtm/gtmDataLayer'
import { StaticImage } from '../../image/StaticImage'
import { SHOP_ENV, TENANT } from '../../tenant/tenantTypes'
import { useExperimentsContext } from '../components/ExperimentsContext'
import { useExperimentsPageType } from '../components/ExperimentsPageTypeContext'
import {
  EXPERIMENTS_TRACK_EVENT_ERX_MODAL_CLOSE_MODAL,
  EXPERIMENTS_TRACK_EVENT_ERX_MODAL_SEEN,
  experimentsTrackEvent,
} from '../eventTracking'
import { ExperimentPageType } from '../ExperimentPageType'
import { useGetExperimentForTenant } from '../hooks'
import { EXPERIMENT_NAME_ERX_MODAL } from '../model/EXPERIMENT_NAME_PER_TENANT'
import { EXPERIMENT_VARIATION } from '../model/EXPERIMENT_VARIATION'
import { type ExperimentType } from '../model/Experiment.types'

import { ErxModalContent } from './ErxModalContent'
import modalHero from './images/erx-modal-hero.webp'

const ERX_MODAL_TIMEOUT = 10_000
const BLACKLISTED_EXPAS = new Set(['dvd'])
const ERX_MODAL_ID = 'ErxModal'
const EXPERIMENT_PAGE_VISIT_COUNT = 2

const useOpenModal = (
  shouldErxModalBeShown: boolean,
  modalControls: OpenableControls,
  experimentErxModal: ExperimentType,
): (() => void) => {
  const timeoutRef = useRef<number | null>(null)
  const erxModalTimeout =
    experimentErxModal.variant === EXPERIMENT_VARIATION.V1
      ? 0
      : ERX_MODAL_TIMEOUT
  const globalContext = useGlobalConfigContext()
  const timeoutCallback = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      gtmDataLayerPush({
        event: 'popin',
        popinName: '__eRxModal',
      })

      if (shouldErxModalBeShown) {
        modalControls.handleOpen()
        experimentsTrackEvent(
          globalContext,
          EXPERIMENTS_TRACK_EVENT_ERX_MODAL_SEEN,
        )
      }
    }, erxModalTimeout)
  }, [erxModalTimeout, globalContext, modalControls, shouldErxModalBeShown])
  useEffect(() => {
    return (): void => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return timeoutCallback
}

const useErxModalShouldBeShown = (erxModalShown: boolean): boolean => {
  const globalContext = useGlobalConfigContext()
  const pageType = useExperimentsPageType()
  const expa = useExpaContext() ?? ''
  const blacklistPages: ExperimentPageType[] = useMemo(() => {
    return [
      ExperimentPageType.Account,
      ExperimentPageType.ForgotPassword,
      ExperimentPageType.Newsletter,
      ExperimentPageType.Cart,
      ExperimentPageType.Checkout,
      ExperimentPageType.Login,
      ExperimentPageType.Search,
    ]
  }, [])

  return (
    globalContext.tenant === TENANT.COM &&
    !erxModalShown &&
    !BLACKLISTED_EXPAS.has(expa) &&
    !blacklistPages.includes(pageType) &&
    globalContext.shopEnv === SHOP_ENV.PROD
  )
}

//We only activate the experiment, after a timeout for V2 without showing the modal
const useHandleV2 = (
  shouldErxModalBeShown: boolean,
  experimentErxModal: ExperimentType,
): void => {
  const { addExperimentKeysForActivation } = useExperimentsContext()
  useEffect(() => {
    if (
      shouldErxModalBeShown &&
      experimentErxModal.isEnabled &&
      experimentErxModal.variant === EXPERIMENT_VARIATION.V2
    ) {
      const activateV2Experiment = setTimeout(() => {
        addExperimentKeysForActivation(experimentErxModal.name)
      }, ERX_MODAL_TIMEOUT)

      return (): void => {
        clearTimeout(activateV2Experiment)

        return undefined
      }
    }

    return undefined
  }, [
    addExperimentKeysForActivation,
    experimentErxModal.isEnabled,
    experimentErxModal.name,
    experimentErxModal.variant,
    shouldErxModalBeShown,
  ])
}

const useActivateErxModalExperiment = (
  experimentErxModal: ExperimentType,
  shouldActivateExperiment: boolean,
): void => {
  const { addExperimentKeysForActivation } = useExperimentsContext()
  useEffect(() => {
    if (shouldActivateExperiment) {
      const timeout = setTimeout(() => {
        addExperimentKeysForActivation(experimentErxModal.name)
      }, ERX_MODAL_TIMEOUT)

      return (): void => {
        clearTimeout(timeout)
      }
    }

    return undefined
  }, [
    addExperimentKeysForActivation,
    experimentErxModal.name,
    shouldActivateExperiment,
  ])
}

//The modal is only shown on the 2nd page visit for V1
const useHandleV1 = (
  shouldErxModalBeShown: boolean,
  experimentErxModal: ExperimentType,
  modalControls: OpenableControls,
): void => {
  const [pageVisits, setPageVisits] = useSessionStorage(
    'erxModalExperimentPageVisitCount',
    0,
  )
  const isExperimentErxModalV1 =
    experimentErxModal.isEnabled &&
    experimentErxModal.variant === EXPERIMENT_VARIATION.V1
  const openModalWithTimeout = useOpenModal(
    shouldErxModalBeShown,
    modalControls,
    experimentErxModal,
  )
  const shouldActivateExperiment =
    shouldErxModalBeShown && isExperimentErxModalV1

  useEffect(() => {
    if (!shouldErxModalBeShown || !isExperimentErxModalV1) {
      return
    }

    setPageVisits((prev) => prev + 1)
  }, [isExperimentErxModalV1, setPageVisits, shouldErxModalBeShown])

  useEffect(() => {
    if (pageVisits === EXPERIMENT_PAGE_VISIT_COUNT && shouldErxModalBeShown) {
      openModalWithTimeout()
    }
  }, [openModalWithTimeout, pageVisits, shouldErxModalBeShown])

  useActivateErxModalExperiment(experimentErxModal, shouldActivateExperiment)
}

const useHandleDefault = (
  shouldErxModalBeShown: boolean,
  experimentErxModal: ExperimentType,
  modalControls: OpenableControls,
): void => {
  const hasOpenedRef = useRef(false)
  const openModalWithTimeout = useOpenModal(
    shouldErxModalBeShown,
    modalControls,
    experimentErxModal,
  )
  const shouldActivateExperiment =
    shouldErxModalBeShown &&
    experimentErxModal.variant === EXPERIMENT_VARIATION.DEFAULT

  useEffect(() => {
    if (hasOpenedRef.current) {
      return
    }

    if (
      !experimentErxModal.isEnabled ||
      experimentErxModal.variant === EXPERIMENT_VARIATION.DEFAULT
    ) {
      openModalWithTimeout()
      hasOpenedRef.current = true
    }
  }, [
    experimentErxModal.isEnabled,
    experimentErxModal.variant,
    openModalWithTimeout,
  ])

  useActivateErxModalExperiment(experimentErxModal, shouldActivateExperiment)
}

/**
 * The modal is automatically opened after a delay specified by ERX_MODAL_TIMEOUT.
 * When the modal is opened, a 'popin' event is pushed to the GTM data layer.
 */
const ErxModal = (): JSX.Element => {
  const modalControls = useOpenableControls(false)
  const [erxModalShown, setErxModalShown] = useSessionStorage(
    ERX_MODAL_ID,
    false,
  )
  const globalContext = useGlobalConfigContext()
  const experimentErxModal = useGetExperimentForTenant(
    EXPERIMENT_NAME_ERX_MODAL,
  )
  const shouldErxModalBeShown = useErxModalShouldBeShown(erxModalShown)

  useHandleDefault(shouldErxModalBeShown, experimentErxModal, modalControls)
  useHandleV1(shouldErxModalBeShown, experimentErxModal, modalControls)
  useHandleV2(shouldErxModalBeShown, experimentErxModal)

  const onModalClose = (): void => {
    setErxModalShown(true)
    setTimeout(() => {
      modalControls.handleClose()
    }, 0)
  }

  return (
    <Dialog
      onClose={onModalClose}
      open={modalControls.isOpen}
      slotProps={{
        dialog: {
          className:
            'mobile-sm:w-11/12 max-w-xl bg-transparent h-auto mobile-sm:-mt-20',
        },
      }}
    >
      <div className="relative">
        <DialogTitle
          className="absolute right-3 top-3 bg-transparent p-0"
          onClose={() => {
            if (experimentErxModal.isEnabled) {
              experimentsTrackEvent(
                globalContext,
                EXPERIMENTS_TRACK_EVENT_ERX_MODAL_CLOSE_MODAL,
              )
            }
            onModalClose()
          }}
        />
      </div>

      <div className="bg-light-brand-high mobile-sm:rounded-xl mobile-sm:rounded-b-none">
        <StaticImage
          alt="redeem-recipe-hero"
          className="m-auto"
          src={modalHero}
          width={324}
        />
      </div>

      <ErxModalContent
        experimentErxModal={experimentErxModal}
        onModalClose={onModalClose}
      />
    </Dialog>
  )
}

export { ErxModal }
