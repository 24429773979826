import { useState } from 'react'

const PREVIOUS_SEARCHES_STORAGE_KEY = 'headerSearchQueriesHistory'
const MAX_PREVIOUS_SEARCHES_COUNT = 3

const previousSearchesStorageGetPreviousSearches = (): string[] => {
  try {
    const previousSearches = localStorage.getItem(PREVIOUS_SEARCHES_STORAGE_KEY)

    if (!previousSearches) {
      return []
    }

    return JSON.parse(previousSearches) as string[]
  } catch {
    return []
  }
}

export const previousSearchesStorageAddPreviousSearches = (
  searchQuery: string,
): void => {
  const previousSearches = previousSearchesStorageGetPreviousSearches()

  if (previousSearches.includes(searchQuery)) {
    return
  }

  const newPreviousSearches = [...previousSearches, searchQuery]

  if (newPreviousSearches.length > MAX_PREVIOUS_SEARCHES_COUNT) {
    newPreviousSearches.shift()
  }

  try {
    localStorage.setItem(
      PREVIOUS_SEARCHES_STORAGE_KEY,
      JSON.stringify(newPreviousSearches),
    )
  } catch {
    // ignore if `localStorage` is not available
  }
}

const previousSearchesStorageClearPreviousSearches = (): void => {
  try {
    localStorage.removeItem(PREVIOUS_SEARCHES_STORAGE_KEY)
  } catch {
    // ignore if `localStorage` is not available
  }
}

/**
 * previous search queries of the user are stored in local storage.
 * hook gets information about them
 */
export const usePreviousSearchControls = (): {
  hasPreviousSearches: boolean
  previousSearches: string[]
  resetPreviousSearches: () => void
} => {
  const [previousSearches, setPreviousSearches] = useState<string[]>(
    previousSearchesStorageGetPreviousSearches,
  )

  return {
    hasPreviousSearches: previousSearches.length > 0,
    previousSearches,
    resetPreviousSearches: (): void => {
      previousSearchesStorageClearPreviousSearches()
      setPreviousSearches([])
    },
  }
}
