import { clsx } from 'clsx'
import { type FC } from 'react'
import { createPortal } from 'react-dom'

import { ErrorBoundary } from '../../../../../error-boundary'
import { useHeaderSearchContext } from '../../headerSearchContext'
import { HeaderSearchMobileInput } from '../HeaderSearchSmallMobileInput/HeaderSearchMobileInput'

import { DynamicHeaderSearchAlgoliaContainer } from './HeaderSearchAlgoliaContainer/DynamicHeaderSearchAlgoliaContainer'

export const HeaderSearchActiveSearchBox: FC<{ className?: string }> = ({
  className,
}) => {
  const context = useHeaderSearchContext()

  const { inputControls, portalResultsTo } = context

  const content = (
    <div
      className={clsx(
        className,
        'fixed top-0 z-40 flex size-full flex-col rounded-3xl bg-light-brand-low p-5 shadow-m tablet:relative tablet:top-auto tablet:h-auto tablet:bg-light-primary-low',
        !context.shouldShowDefaultRecommendations() &&
          !inputControls.renderedSearchRecords?.length &&
          'tablet:hidden',
      )}
      data-qa-id="suggest-list"
    >
      <ErrorBoundary>
        <HeaderSearchMobileInput className="tablet:hidden" />
        <div className="-mx-5 flex flex-col overflow-y-auto px-5">
          <DynamicHeaderSearchAlgoliaContainer />
        </div>
      </ErrorBoundary>
    </div>
  )

  if (!portalResultsTo) {
    return content
  }

  return createPortal(content, portalResultsTo)
}
