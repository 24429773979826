import { type FC, type ReactNode } from 'react'

import { type TENANT } from '../../tenant/tenantTypes'
import { type EXPERIMENT_VARIATION } from '../model/EXPERIMENT_VARIATION'

export type ExperimentVariantProps = {
  children: Awaited<ReactNode>
  name: EXPERIMENT_VARIATION
  // tenant prop is checked in the parent `Experiment` component
  tenant?: TENANT | TENANT[] | null
}

export const ExperimentVariant: FC<ExperimentVariantProps> = ({ children }) =>
  children
