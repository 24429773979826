import { clsx } from 'clsx'
import { type FC } from 'react'

import { useExperimentalAddStaticFileHost } from '../../../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import { useGlobalConfigContext } from '../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../image'
import { assetUrlGetPrefixed } from '../../../../../url-handling'
import { type FooterTrustBoxLink } from '../../../config/PerTenantFooterConfiguration.type'

const iconsMap = {
  capital: 'capital-fr-horizontal.png',
  customerFriendlinessAward: 'customer-friendliness-award.png',
  download: 'download.svg',
  downloadIt: 'download-it.png',
  eaep: 'eaep.svg',
  secureGlobalSign: 'secure-global-sign.png',
  swisspost: 'swisspost.svg',
  swisspostFr: 'swisspost-fr.svg',
  trustedshops: 'trusted.svg',
  trustpilotBe: 'trustpilot-be.png',
  trustpilotIt: 'trustpilot-it.png',
  tuv: 'tuv.svg',
  tuvCert: 'tuv-certificate.jpg',
  vet: 'vet.png',
  vetIt: 'vet-it.png',
  vsv: 'vsv-ch.png',
  webshopaward: 'webshop-award.svg',
}

const getLogoPath = ({
  assetsPrefix,
  iconId,
}: {
  assetsPrefix: string
  iconId: string
}): string => {
  const namePart = iconsMap[iconId as keyof typeof iconsMap]

  return namePart
    ? assetUrlGetPrefixed(assetsPrefix, `/logos/logo-${namePart}`)
    : ''
}

type FooterTrustedProps = {
  className?: string
  trustedItems: FooterTrustBoxLink[]
}

export const FooterTrusted: FC<FooterTrustedProps> = ({
  className,
  trustedItems,
}) => {
  const { assetsPrefix } = useGlobalConfigContext()

  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

  return (
    <ul
      className={clsx(
        'container flex flex-wrap justify-center py-3.5 tablet:justify-between',
        className,
      )}
    >
      {trustedItems.map((item) => {
        const image = (
          <NextImageWithFallback
            alt={item.alt}
            className="size-full object-contain"
            fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
            height={10}
            sizes="100vw"
            src={getLogoPath({ assetsPrefix, iconId: item.id })}
            width={10}
          />
        )
        let modifiedItem = item
        if (['capital', 'tuv', 'tuvCert'].includes(item.id)) {
          modifiedItem = {
            ...item,
            href: addStaticCdnHostToUrl(item.href),
          }
        }

        return (
          <li className="m-2 h-16" key={modifiedItem.id}>
            {modifiedItem.href ? (
              <a href={modifiedItem.href}>{image}</a>
            ) : (
              <span>{image}</span>
            )}
          </li>
        )
      })}
    </ul>
  )
}
