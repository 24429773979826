'use client'

import { useEffect } from 'react'

/**
 * so we use less wait in end 2 end tests,
 * as soon as react is ready, we will mark body with special attribute.
 * this can be used in e.g. cypress to understand that the page is interactive
 */
export const useHintHydrationReadyForEndToEndTest = (): void => {
  useEffect(() => {
    const body = document.querySelector('body')

    if (body) {
      body.dataset.hydrated = 'true'
    }
  }, [])
}
