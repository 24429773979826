'use client'

import { useEffect, useState } from 'react'

import { useHeaderSearchContext } from '../../standard-shop-layout/header/header-search/headerSearchContext'
import { useExperimentsContext } from '../components/ExperimentsContext'
import { useGetExperiment } from '../hooks'
import { EXPERIMENT_NAME_NEURAL_SEARCH } from '../model/EXPERIMENT_NAME'

/**
 * Hook to activate the neural search experiment
 * when the user starts typing in the search box.
 */
export const useActivateNeuralSearchExperiment = (): void => {
  const [isSearchInitialized, setIsSearchInitialized] = useState(false)
  const { addExperimentKeysForActivation } = useExperimentsContext()
  const neuralSearchExperiment = useGetExperiment(EXPERIMENT_NAME_NEURAL_SEARCH)
  const headerSearchContext = useHeaderSearchContext()
  const isSearchInitiated = headerSearchContext.inputControls.searchQuery

  useEffect(() => {
    if (
      isSearchInitialized ||
      !isSearchInitiated ||
      !neuralSearchExperiment.isEnabled
    ) {
      return
    }

    setIsSearchInitialized(true)
    addExperimentKeysForActivation(EXPERIMENT_NAME_NEURAL_SEARCH)
  }, [
    addExperimentKeysForActivation,
    isSearchInitialized,
    isSearchInitiated,
    neuralSearchExperiment.isEnabled,
  ])
}
