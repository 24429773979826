import { type FC } from 'react'

import { type FooterDataType } from '../../model/footer.types'

import { FooterBannerSection } from './FooterBannerSection'
import { FooterCategoriesSection } from './FooterCategoriesSection'
import { FooterCopyrightAndShops } from './FooterCopyrightAndShops'
import { FooterLegalText } from './FooterLegalText'
import { FooterSocialAndApps } from './FooterSocialAndApps'
import { FooterTrusted } from './FooterTrusted'
import { FooterUsp } from './FooterUsp'

type FooterProps = {
  footerData: FooterDataType
}

export const Footer: FC<FooterProps> = ({
  footerData: {
    appLinks,
    footerCmsData: { data },
    infoSectionLinks,
    legalTextIframeSrc,
    newsletterDiscount,
    paymentPartners,
    shippingPartners,
    socialLinks,
    tenantLinks,
    trustBoxLinks,
    uspBoxItems,
  },
}) => (
  <footer>
    <FooterCategoriesSection categories={data?.categories} />
    <FooterUsp uspItems={uspBoxItems} />
    <FooterTrusted trustedItems={trustBoxLinks} />
    <FooterBannerSection
      infoSectionItems={infoSectionLinks}
      newsletterDiscount={newsletterDiscount}
      paymentPartnersItems={paymentPartners}
      shippingPartnersItems={shippingPartners}
    />
    <FooterSocialAndApps
      appLinksItems={appLinks}
      socialLinksItems={socialLinks}
    />
    <FooterCopyrightAndShops tenantItemsLinks={tenantLinks} />
    <FooterLegalText legalTextIframeSrc={legalTextIframeSrc} />
  </footer>
)
