import { clsx } from 'clsx'
import { type FC } from 'react'

import { IconCaretDown, IconCheck } from '@redteclab/icons'

import { useGlobalConfigContext } from '../../../../global-config'
import { useAlternativeUrlsContext } from '../../../alternativeUrls'

export const LanguageSwitcherSelect: FC = () => {
  const { language } = useGlobalConfigContext()
  const alternateLanguages = useAlternativeUrlsContext()
  const supportedLanguages = Object.keys(alternateLanguages)

  if (supportedLanguages.length <= 1) {
    return null
  }

  return (
    <div className="group relative" role="menu">
      <button
        className="flex items-center whitespace-nowrap text-xs leading-none hover:text-dark-brand"
        type="button"
      >
        {alternateLanguages[language].translatedLanguage}{' '}
        <IconCaretDown className="ml-1" />
      </button>
      <ul className="absolute -right-3.5 top-full hidden min-w-40 overflow-hidden rounded-2xl bg-light-primary-low font-medium shadow-m group-hover:block">
        {supportedLanguages.map((languageCode) => (
          <li key={languageCode}>
            <a
              className={clsx(
                'flex w-full items-center py-3 pl-6 pr-4 hover:bg-light-primary-medium',
                language === languageCode && 'bg-light-primary-medium',
              )}
              data-lang={languageCode}
              href={alternateLanguages[languageCode].path}
              role="menuitem"
            >
              {alternateLanguages[languageCode].translatedLanguage}
              {language === languageCode && (
                <IconCheck className="ml-auto text-xl" />
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
