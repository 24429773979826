import {
  Children,
  isValidElement,
  type ReactElement,
  type ReactNode,
} from 'react'

import { type TENANT } from '../../tenant/tenantTypes'
import { EXPERIMENT_VARIATION } from '../model/EXPERIMENT_VARIATION'
import { type ExperimentType } from '../model/Experiment.types'

import { type ExperimentVariantProps } from './ExperimentVariant'

type ExperimentVariantNode = {
  props: {
    name: EXPERIMENT_VARIATION
    tenants?: TENANT[]
  }
}

type ExperimentVariantElement = ReactElement<ExperimentVariantProps>

const validateTenant = (
  variant: ExperimentVariantNode,
  tenant: TENANT,
): boolean => {
  const tenantsFromVariant = variant.props.tenants

  const shouldMatch = tenantsFromVariant?.some(Boolean)

  return shouldMatch && tenantsFromVariant
    ? tenantsFromVariant.includes(tenant)
    : true
}

const getDefaultAndMatchedVariant = ({
  children,
  experiment,
  tenant,
}: {
  children: ExperimentVariantElement | ExperimentVariantElement[]
  experiment: ExperimentType
  tenant: TENANT
}): {
  defaultVariant: ExperimentVariantElement | null
  matchedVariant: ExperimentVariantElement | null
} => {
  let matchedVariant: ExperimentVariantElement | null = null
  let defaultVariant: ExperimentVariantElement | null = null

  Children.forEach(children, (childNode) => {
    if (matchedVariant ?? !isValidElement(childNode)) {
      return
    }

    const { name: variantName } = childNode.props
    if (
      experiment.variant === variantName &&
      validateTenant(childNode, tenant)
    ) {
      matchedVariant = childNode
    }

    if (variantName === EXPERIMENT_VARIATION.DEFAULT) {
      defaultVariant = childNode
    }
  })

  return { defaultVariant, matchedVariant }
}

export const experimentComponentGetMatchedVariant = (
  experiment: ExperimentType,
  children: ExperimentVariantElement | ExperimentVariantElement[],
  tenant: TENANT,
): ReactElement | null => {
  const { defaultVariant, matchedVariant } = getDefaultAndMatchedVariant({
    children,
    experiment,
    tenant,
  })
  // if there is already a matched variant, return it
  if (matchedVariant) {
    return matchedVariant
  }
  // if there is no matched variant, but there is a valid default variant, return it
  if (isValidElement(defaultVariant)) {
    return defaultVariant
  }

  // @ts-expect-error as last solution, if it exists and valid, we use it
  const firstChild = children[0] as ReactNode
  if (firstChild && isValidElement(firstChild)) {
    return firstChild
  }

  return matchedVariant
}
