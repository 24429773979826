import { clsx } from 'clsx'
import { type FC, type JSX } from 'react'
import { useIntl } from 'react-intl'

import { AccordionDetails, AccordionSummary } from 'base-ui'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import { assetUrlGetPrefixed } from '../../../../../../url-handling'
import {
  type FooterPaymentPartner,
  type FooterPaymentPartnerLogo,
} from '../../../../config/PerTenantFooterConfiguration.type'
import { FooterBannerSectionAccordion } from '../FooterBannerSectionAccordion'

const iconMap = new Map([
  ['logoApplePay', 'applepay-padded-and-centered.svg'],
  ['logoBancontact', 'bancontact.svg'],
  ['logoBankTransfer', 'bank-transfer.svg'],
  ['logoBonificoBancario', 'bonifico-bancario-it.svg'],
  ['logoContrassegno', 'contrassegno-it.svg'],
  ['logoContrassegno', 'contrassegno-it.svg'],
  ['logoFacture', 'facture.svg'],
  ['logoKlarna', 'klarna.svg'],
  ['logoMaestro', 'maestro.svg'],
  ['logoMastercard', 'mastercard-padded-and-centered.svg'],
  ['logoNexxi', 'nexxi-it.svg'],
  ['logoPaypal', 'paypal-padded-and-centered.svg'],
  ['logoPostePay', 'postepay-it.svg'],
  ['logoPostFinance', 'twint-post-finance-ch.svg'],
  ['logoRechnung', 'rechnung.svg'],
  ['logoSepa', 'sepa-padded-and-centered.svg'],
  ['logoVisa', 'visa.svg'],
  ['logoVorkasse', 'vorkasse.svg'],
])

const iconRequiresPadding = (iconId: string): boolean =>
  ['logoVisa'].includes(iconId)

const getLogoPath = ({
  assetsPrefix,
  iconId,
}: {
  assetsPrefix: string
  iconId: string
}): string => {
  const namePart = iconMap.get(iconId)

  return namePart
    ? assetUrlGetPrefixed(assetsPrefix, `/logos/logo-${namePart}`)
    : ''
}

type FooterPaymentPartnersProps = {
  className?: string
  paymentPartnersItems: FooterPaymentPartner[]
}

export const FooterPaymentPartners: FC<FooterPaymentPartnersProps> = ({
  className,
  paymentPartnersItems,
}) => {
  const { assetsPrefix } = useGlobalConfigContext()
  const intl = useIntl()
  const headerText = intl.formatMessage({ id: 'footer.headline.payments' })

  const renderImage = (
    alt: string,
    logo: FooterPaymentPartnerLogo,
  ): JSX.Element => (
    <NextImageWithFallback
      alt={alt}
      className="size-full object-contain"
      fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
      height={10}
      src={getLogoPath({ assetsPrefix, iconId: logo.src })}
      width={10}
    />
  )

  return (
    <FooterBannerSectionAccordion className={className} id="payment-partners">
      <AccordionSummary>{headerText}</AccordionSummary>
      <AccordionDetails>
        <ul className="grid auto-rows-fr grid-cols-3 gap-3.5">
          {paymentPartnersItems.map(({ alt, href, logo }) => (
            <li
              className={clsx(
                'flex max-h-16 w-full items-center justify-center overflow-hidden rounded-2xl bg-light-primary-low',
                iconRequiresPadding(logo.src) && 'p-2',
              )}
              key={logo.src}
            >
              {href ? (
                <a className="size-full" href={href}>
                  {renderImage(alt, logo)}
                </a>
              ) : (
                <span className="size-full">{renderImage(alt, logo)}</span>
              )}
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </FooterBannerSectionAccordion>
  )
}
