import { type FC } from 'react'

import { AccordionDetails, AccordionSummary } from 'base-ui'

import { type FooterMenuCategory } from '../../../../config/PerTenantFooterConfiguration.type'
import { FooterLinkCategory } from '../../FooterLinkCategory'
import { FooterBannerSectionAccordion } from '../FooterBannerSectionAccordion'

type FooterInfoSectionProps = {
  category: FooterMenuCategory
  className?: string
}

export const FooterInfoSection: FC<FooterInfoSectionProps> = ({
  category,
  className,
}) => (
  <FooterBannerSectionAccordion
    className={className}
    id={category.title.replaceAll(' ', '_')}
  >
    <AccordionSummary>{category.title}</AccordionSummary>
    <AccordionDetails>
      <FooterLinkCategory category={category} noTitle />
    </AccordionDetails>
  </FooterBannerSectionAccordion>
)
