import { clsx } from 'clsx'
import { forwardRef } from 'react'

import { useHeaderDataContext } from '../../headerConfigContext'
import { LanguageSwitcherSelect } from '../LanguageSwitcher'

import { Tag } from './Tag'

export const HeaderTopSection = forwardRef<HTMLDivElement>(
  function InnerHeaderTopSection(props, ref) {
    const { menuLinks } = useHeaderDataContext()

    return (
      <div
        className="relative z-20 hidden bg-light-primary-low hover:z-40 tablet:block"
        ref={ref}
      >
        <div className="container flex flex-wrap items-center justify-between pt-2 text-s">
          <ul
            className={clsx('inline-flex pr-4', 'desktop:ml-5 desktop:pl-48')}
          >
            {menuLinks.center.map((link, index) => (
              <li className="mr-4" key={link.id}>
                <Tag hasBorder={index === 0} href={link.href}>
                  {link.text}
                </Tag>
              </li>
            ))}
          </ul>
          <ul className="inline-flex items-center">
            {menuLinks.right.map((link) => (
              <li className="ml-2" key={link.id}>
                <a
                  aria-label={link.text}
                  className="text-xs text-dark-primary-max hover:text-dark-hover-brand"
                  href={link.href}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
          <LanguageSwitcherSelect />
        </div>
      </div>
    )
  },
)
