import { ExperimentPageType } from '../ExperimentPageType'

import {
  EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW,
  EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST,
  EXPERIMENT_NAME_HOME_ONE_ACCOUNT,
} from './EXPERIMENT_NAME'
import { EXPERIMENT_BREADCRUMBS } from './EXPERIMENT_NAME_PER_TENANT'
import { type TenantBasedExperiment } from './Experiment.types'

export const experimentsPerPage = {
  [ExperimentPageType.Account]: [EXPERIMENT_NAME_HOME_ONE_ACCOUNT],
  [ExperimentPageType.Default]: [EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST],
  [ExperimentPageType.Product]: [
    EXPERIMENT_BREADCRUMBS,
    EXPERIMENT_NAME_CTA_BUTTON_ON_ALL_CSW,
  ],
} satisfies Partial<
  Record<ExperimentPageType, (TenantBasedExperiment | string)[]>
>
