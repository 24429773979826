import { clsx } from 'clsx'
import { type JSX } from 'react'

import { DialogTitle } from 'base-ui'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { LOCALE } from '../../i18n/LOCALE'

type NewsletterModalTitleProps = {
  onModalClose: () => void
}

export const NewsletterModalTitle = ({
  onModalClose,
}: NewsletterModalTitleProps): JSX.Element => {
  const globalConfig = useGlobalConfigContext()

  const isLocaleItIt = globalConfig.locale === LOCALE.IT_IT

  return (
    <div className="relative">
      <DialogTitle
        className="absolute right-3 top-3 bg-transparent p-0"
        onClose={onModalClose}
        slotProps={{
          closeButton: {
            className: clsx(
              'fill-current',
              isLocaleItIt && 'text-light-primary-strong',
            ),
          },
        }}
      />
    </div>
  )
}
