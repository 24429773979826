import { type KeyboardEvent } from 'react'

import {
  type AlgoliaQueryRecord,
  type AlgoliaRecord,
} from '../../../../../../../../algolia'
import {
  baseKeyboardEventAccessorIsArrowDown,
  baseKeyboardEventAccessorIsArrowUp,
  baseKeyboardEventAccessorIsEscape,
} from '../../../../../../../../base-keyboard-handling'
import { type HeaderSearchContextValue } from '../../../../types'

const findIndexByName = (
  itemsToRender: (AlgoliaQueryRecord | AlgoliaRecord)[],
  value: string | undefined,
): number =>
  itemsToRender.findIndex((hit) => {
    // check the type of the hit
    if ('productName' in hit) {
      return hit.productName === value
    }

    return hit.query === value
  })

/**
 * clears input value to empty
 */
const handleEscapeKey = (
  headerSearchContext: HeaderSearchContextValue,
): void => {
  headerSearchContext.inputControls.setSearchInputValue('', {
    updateSearchQuery: true,
  })
}

/**
 * gets the currently displayed search records and suggestions and combines them into one array
 */
const getCombinedSearchRecordsAndSuggestions = (
  headerSearchContext: HeaderSearchContextValue,
): (AlgoliaQueryRecord | AlgoliaRecord)[] => {
  const searchRecords =
    headerSearchContext.inputControls.renderedSearchRecords ?? []
  const searchSuggestions =
    headerSearchContext.inputControls.renderedSuggestionRecords ?? []

  return [...searchSuggestions, ...searchRecords]
}

/**
 * gets the currently displayed search records,
 * finds the one that matches the current search input value,
 * and returns the index of that record
 * if not found returns -1
 * @example
 * value in input = 'aspirin'
 * itemsToRender = ['foo', 'bar', 'aspirin']
 * returns 2
 *
 * itemsToRender = ['foo', 'bar']
 * returns -1
 */
const getIndexOfRecordOrSuggestionNameMatchesValueInSearch = (
  headerSearchContext: HeaderSearchContextValue,
): number => {
  const currentSearchInputValue =
    headerSearchContext.inputControls.searchInputValue

  const itemsToRender =
    getCombinedSearchRecordsAndSuggestions(headerSearchContext)

  return findIndexByName(itemsToRender, currentSearchInputValue)
}

const getRenderedValueOfRecordOrSuggestion = (
  hit: AlgoliaQueryRecord | AlgoliaRecord | undefined,
): string => {
  if (!hit) {
    return ''
  }
  if ('query' in hit) {
    return hit.query
  }

  return hit.productName
}

/**
 * rendered search records
 * ['foo', 'bar', 'baz']
 * for search value: '' (goes first)
 * fills search value 'foo'
 *
 * for search value 'foo' (goes next)
 * fills search value 'bar'
 *
 * for search value 'baz' (last record -> to what was initially in input)
 * fills initial value of input
 */
const autoCompleteOnArrowDown = (
  headerSearchContext: HeaderSearchContextValue,
): void => {
  const matchingCurrentIndex =
    getIndexOfRecordOrSuggestionNameMatchesValueInSearch(headerSearchContext)

  const itemsToRender =
    getCombinedSearchRecordsAndSuggestions(headerSearchContext)

  /*
   * input matches no product name of any record
   * set first record which is a suggestion
   */
  if (matchingCurrentIndex === -1) {
    headerSearchContext.inputControls.setSearchInputValue(
      getRenderedValueOfRecordOrSuggestion(itemsToRender[0]),
      {
        updateSearchQuery: false,
      },
    )

    return
  }

  // has next
  if (matchingCurrentIndex + 1 < itemsToRender.length) {
    headerSearchContext.inputControls.setSearchInputValue(
      getRenderedValueOfRecordOrSuggestion(
        itemsToRender[matchingCurrentIndex + 1],
      ),
      {
        updateSearchQuery: false,
      },
    )

    return
  }

  // is last, reset to what was in input
  if (matchingCurrentIndex + 1 === itemsToRender.length) {
    headerSearchContext.inputControls.setSearchInputValue(
      headerSearchContext.inputControls.searchQuery,
      {
        updateSearchQuery: false,
      },
    )
  }
}

/**
 * reverse logic of {@link autoCompleteOnArrowDown}
 */
const autocompleteOnArrowUp = (
  headerSearchContext: HeaderSearchContextValue,
): void => {
  const matchingCurrentIndex =
    getIndexOfRecordOrSuggestionNameMatchesValueInSearch(headerSearchContext)

  const itemsToRender =
    getCombinedSearchRecordsAndSuggestions(headerSearchContext)

  // no match found
  if (matchingCurrentIndex === -1) {
    const productName = getRenderedValueOfRecordOrSuggestion(
      itemsToRender.at(-1),
    )

    if (!productName) {
      return
    }

    headerSearchContext.inputControls.setSearchInputValue(productName, {
      updateSearchQuery: false,
    })

    return
  }

  // is first record in list of suggestions and records -> reset to what was in input
  if (matchingCurrentIndex - 1 < 0) {
    headerSearchContext.inputControls.setSearchInputValue(
      headerSearchContext.inputControls.searchQuery,
      {
        updateSearchQuery: false,
      },
    )

    return
  }

  // has previous record in list of suggestions and records -> set previous record
  if (matchingCurrentIndex - 1 > -1) {
    headerSearchContext.inputControls.setSearchInputValue(
      getRenderedValueOfRecordOrSuggestion(
        itemsToRender[matchingCurrentIndex - 1],
      ),
      {
        updateSearchQuery: false,
      },
    )
  }
}

/**
 * When search input has focus and the user presses the arrow keys:
 * - if search results present depending on arrow key will put product name of next/previous result as input value.
 * if presses escape:
 * - will clear search input
 * if presses enter:
 * - will submit the search
 * IMPORTANT:
 * arrrow key just sets the input value, it does not update the search query/retrigger search.
 * This is required for just an "autocomplete" feature.
 */
export const handleKeyPressedOnSearchInput = (
  event: KeyboardEvent<HTMLInputElement>,
  headerSearchContext: HeaderSearchContextValue,
): void => {
  if (baseKeyboardEventAccessorIsArrowDown(event)) {
    autoCompleteOnArrowDown(headerSearchContext)

    return
  }

  if (baseKeyboardEventAccessorIsArrowUp(event)) {
    autocompleteOnArrowUp(headerSearchContext)
  }

  if (baseKeyboardEventAccessorIsEscape(event)) {
    handleEscapeKey(headerSearchContext)
  }
}
