import { type FC } from 'react'

import {
  type CmsComponentMenu,
  CmsComponentMenuActionSchemaEnum,
} from '@redteclab/api/clients/content-management-system'

import { useExpaContext } from '../../../../../expa'

import { HeaderCategoryLinkItem } from './HeaderCategoryLinkItem'

interface HeaderCategoryColumnProps {
  className: string
  columnData: CmsComponentMenu
  isFirstColumn: boolean
}

export const HeaderCategoryColumn: FC<HeaderCategoryColumnProps> = ({
  className,
  columnData,
  isFirstColumn,
}) => {
  const expa = useExpaContext()

  return (
    <div className={className} key={columnData.title}>
      <h3 className="mb-3.5 whitespace-nowrap text-m font-bold leading-xl tracking-[.05rem]">
        {columnData.title}
      </h3>
      <menu aria-labelledby="categories-menu-button" id="categories-menu">
        {columnData.items?.map((item) => {
          if (
            item.schema !==
            CmsComponentMenuActionSchemaEnum.CmsComponentMenuAction
          ) {
            return null
          }

          if (item.cleansite?.some((cleansite) => cleansite === expa)) {
            return null
          }

          return (
            <HeaderCategoryLinkItem
              isBold={isFirstColumn}
              item={item}
              key={item.title}
            />
          )
        })}
      </menu>
    </div>
  )
}
