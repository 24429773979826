import { ClickAwayListener } from '@mui/base'
import { type FC, type RefObject, useEffect, useRef } from 'react'

import { useHeaderSearchContext } from '../../headerSearchContext'
import { type HeaderSearchContextValue } from '../types'

import { HeaderSearchDesktopInput } from './HeaderSearchDesktopInput'

const useSetSearchInactiveOnEscPress = (
  setSearchIsActive: HeaderSearchContextValue['setSearchIsActive'],
): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setSearchIsActive({ value: false })
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [setSearchIsActive])
}

const useSetSearchInactiveOnTabPress = (
  searchBoxRef: RefObject<HTMLDivElement | null>,
  setSearchIsActive: HeaderSearchContextValue['setSearchIsActive'],
): void => {
  useEffect(() => {
    const handleTabOutside = (event: KeyboardEvent): void => {
      if (event.key === 'Tab') {
        const { activeElement } = document
        if (
          searchBoxRef.current &&
          !searchBoxRef.current.contains(activeElement)
        ) {
          setSearchIsActive({ value: false })
        }
      }
    }

    window.addEventListener('keyup', handleTabOutside)

    return (): void => {
      window.removeEventListener('keyup', handleTabOutside)
    }
  }, [searchBoxRef, setSearchIsActive])
}

const HeaderSearchDesktop: FC<{ className?: string }> = ({ className }) => {
  const { portalResultsTo, searchIsActive, setSearchIsActive } =
    useHeaderSearchContext()
  const searchBoxRef = useRef<HTMLDivElement | null>(null)

  const backdropActive = Boolean(searchIsActive && portalResultsTo)

  useSetSearchInactiveOnEscPress(setSearchIsActive)
  useSetSearchInactiveOnTabPress(searchBoxRef, setSearchIsActive)

  const onClickAway = (): void => {
    if (backdropActive) {
      setSearchIsActive({
        value: false,
      })
    }
  }

  return (
    <div className={className}>
      {backdropActive ? (
        <div className="fixed left-0 top-0 z-10 size-full bg-backdrop" />
      ) : null}
      <ClickAwayListener
        /*
         * In cases when user clicks on another modal backdrop this component invokes onClick event
         * after search input receives focus by `FocusTrap` of the modal.
         * To fix this exception use `mouseEvent="onMouseDown"` & `touchEvent="onTouchStart"` to trigger
         * `onClickAway` handler before input gets focus.
         * Issue example is described in https://jira.shop-apotheke.com/browse/CRO-3838
         */
        mouseEvent="onMouseDown"
        onClickAway={onClickAway}
        touchEvent="onTouchStart"
      >
        <div className="absolute top-0 z-20 size-full" ref={searchBoxRef}>
          <HeaderSearchDesktopInput />
        </div>
      </ClickAwayListener>
    </div>
  )
}

export { HeaderSearchDesktop }
