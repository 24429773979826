'use client'

import Script from 'next/script'
import { type FC } from 'react'

import {
  FEATURE_TOGGLE_SAE_CONVERSATIONAL_CHAT,
  useFeatureToggleContext,
} from '../feature-toggles'
import { useGlobalConfigContext } from '../global-config'
import { useWebviewContext } from '../webview'

export const SaeConversationalChat: FC = () => {
  const publicGlobalConfig = useGlobalConfigContext()
  const featureToggles = useFeatureToggleContext()
  const isEnabled = featureToggles[FEATURE_TOGGLE_SAE_CONVERSATIONAL_CHAT]

  const webview = useWebviewContext()

  if (!isEnabled || webview) {
    return null
  }

  const { deploymentStage, language, tenant } = publicGlobalConfig

  const baseUrl =
    deploymentStage === 'production'
      ? 'https://static.sae-conversational.com'
      : 'https://static-staging.sae-conversational.com'

  return <Script src={`${baseUrl}/tenants/${tenant}/${language}/app-init.js`} />
}
