import { type FC } from 'react'

import { type CmsComponentMenu } from '@redteclab/api/clients/content-management-system'

import { OffCanvasHeaderMenuContextProvider } from '../../OffCanvasHeaderMenuContext'

import { OffCanvasHeaderMenu } from './OffCanvasHeaderMenu'
import { OffCanvasHeaderMenuToggle } from './OffCanvasHeaderMenuToggle'

interface OffCanvasHeaderMenuContainerProps {
  hamburgerMenuData: CmsComponentMenu[]
}

const OffCanvasHeaderMenuContainer: FC<OffCanvasHeaderMenuContainerProps> = ({
  hamburgerMenuData,
}) => {
  return (
    <OffCanvasHeaderMenuContextProvider menuData={hamburgerMenuData}>
      <OffCanvasHeaderMenuToggle />
      <OffCanvasHeaderMenu />
    </OffCanvasHeaderMenuContextProvider>
  )
}

export { OffCanvasHeaderMenuContainer }
