import { clsx } from 'clsx'
import { type FC } from 'react'

import { Accordion, type AccordionProps } from 'base-ui'

import styles from './FooterBannerSectionAccordion.module.css'

export const FooterBannerSectionAccordion: FC<AccordionProps> = ({
  className,
  ...props
}) => (
  <Accordion
    className={clsx(styles['footer-banner-section-accordion'], className)}
    {...props}
  />
)
