import { type FC } from 'react'

import {
  type FooterAppStore,
  type FooterSocialLink,
} from '../../../config/PerTenantFooterConfiguration.type'

import { FooterAppStores } from './FooterAppStores'
import { FooterSocial } from './FooterSocial/FooterSocial'

type FooterSocialAndAppsProps = {
  appLinksItems: FooterAppStore[]
  socialLinksItems: FooterSocialLink[]
}

export const FooterSocialAndApps: FC<FooterSocialAndAppsProps> = ({
  appLinksItems,
  socialLinksItems,
}) => (
  <div className="container flex flex-col gap-3 py-2.5 tablet:flex-row tablet:items-center tablet:justify-between tablet:py-5">
    <FooterSocial socialLinksItems={socialLinksItems} />
    <FooterAppStores appLinksItems={appLinksItems} />
  </div>
)
