import { type FC } from 'react'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import { assetUrlGetPrefixed } from '../../../../../../url-handling'
import { type FooterAppStore } from '../../../../config/PerTenantFooterConfiguration.type'

const logosMap = {
  appstore: 'appstore.svg',
  'appstore-fr': 'appstore-fr.svg',
  'appstore-it': 'appstore-it.svg',
  'google-play': 'google-play.svg',
  'google-play-fr': 'google-play-fr.svg',
  'google-play-it': 'google-play-it.svg',
}

const getLogoPath = ({
  assetsPrefix,
  iconId,
}: {
  assetsPrefix: string
  iconId: string
}): string => {
  const namePart = logosMap[iconId as keyof typeof logosMap]

  return namePart
    ? assetUrlGetPrefixed(assetsPrefix, `/logos/logo-${namePart}`)
    : ''
}

type FooterAppStoresProps = {
  appLinksItems: FooterAppStore[]
}

export const FooterAppStores: FC<FooterAppStoresProps> = ({
  appLinksItems,
}) => {
  const { assetsPrefix } = useGlobalConfigContext()

  if (appLinksItems.length === 0) {
    return null
  }

  return (
    <ul className="flex gap-4">
      {appLinksItems.map(({ alt, href, id }) => (
        <li className="shrink-0" key={id}>
          <a href={href}>
            <NextImageWithFallback
              alt={alt}
              fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
              height={40}
              src={getLogoPath({ assetsPrefix, iconId: id })}
              width={135}
            />
          </a>
        </li>
      ))}
    </ul>
  )
}
