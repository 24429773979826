'use client'

import { sendGTMEvent } from '@next/third-parties/google'
import axios, { isAxiosError } from 'axios'
import {
  type FC,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { apmCaptureError } from '../../apm/apmCaptureError'
import { useDeviceDetectorContext } from '../../device-detector/context/deviceDetectorContext'
import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { type TENANT } from '../../tenant/tenantTypes'
import { ExperimentPageType } from '../ExperimentPageType'
import { type TenantBasedExperiment } from '../model/Experiment.types'
import { experimentAccessorGetExperimentNamePerTenant } from '../model/experimentAccessor'

import {
  ExperimentContext,
  type ExperimentContextType,
} from './ExperimentsContext'
import { ExperimentsPageTypeProvider } from './ExperimentsPageTypeContext'

/**
 * Experiment states:
 * - `not-activated`: The initial state of an experiment when it is received from the server.
 * - `pending-activation`: The experiment is in a queue, waiting to be activated.
 * - `activated`: Indicates that the experiment has been activated.
 */
type ExperimentState = 'not-activated' | 'pending-activation' | 'activated'

type ExperimentStates = Partial<Record<string, ExperimentState>>

type ActivationOptions = {
  isMobile: boolean
  tenant: TENANT
}

const getExperimentKeysForActivation = (
  mixedKeys: (TenantBasedExperiment | string)[],
  experimentState: ExperimentStates,
  { isMobile, tenant }: ActivationOptions,
): string[] => {
  const keys = [mixedKeys]
    .flat()
    .map((key) => {
      if (typeof key === 'string') {
        return key
      }

      return experimentAccessorGetExperimentNamePerTenant({
        experimentName: key,
        isMobile,
        tenant,
      })
    })
    .filter((key) => key !== undefined)

  return keys.filter((key) => experimentState[key] === 'not-activated')
}

const EXPERIMENTS_BATCH_ACTIVATION_DEBOUNCE_TIMEOUT = 300

type ExperimentsContextProviderProps = PropsWithChildren<
  Pick<ExperimentContextType, 'experiments'>
>

export const ExperimentsContextProvider: FC<ExperimentsContextProviderProps> = (
  props,
) => {
  const { children, experiments } = props

  const { language, tenant, tenantAndEnv } = useGlobalConfigContext()
  const { isMobile } = useDeviceDetectorContext()

  const experimentStatesRef = useRef<ExperimentStates>(
    useMemo(
      () =>
        Object.fromEntries(
          experiments
            .filter(({ isEnabled }) => isEnabled)
            .map(({ name }) => [name, 'not-activated']),
        ),
      [experiments],
    ),
  )

  const activatePendingExperiments = useCallback(async () => {
    const keys = Object.entries(experimentStatesRef.current)
      .filter(([, state]) => state === 'pending-activation')
      .map(([name]) => name)

    const experimentsActivationUrl = `/webclient/experiments/${tenantAndEnv}/${language}/activate`

    try {
      await axios.post(experimentsActivationUrl, {
        featureKeys: keys,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        apmCaptureError(error)

        return
      }

      throw error
    }

    sendGTMEvent({
      event: 'exp_activated',
      exp_names: keys,
    })

    for (const key of keys) {
      experimentStatesRef.current[key] = 'activated'
    }
  }, [language, tenantAndEnv])

  const activatePendingExperimentsDebounced = useDebounceCallback(
    // Wrap async function into sync to skip promise related logic & checks
    useCallback(() => {
      void activatePendingExperiments()
    }, [activatePendingExperiments]),
    EXPERIMENTS_BATCH_ACTIVATION_DEBOUNCE_TIMEOUT,
  )

  const addExperimentKeysForActivation: ExperimentContextType['addExperimentKeysForActivation'] =
    useCallback(
      (mixedKeys) => {
        const experimentsForActivation = getExperimentKeysForActivation(
          // Convert single element in array with single item, but keep array of keys as it is
          [mixedKeys].flat(),
          experimentStatesRef.current,
          {
            isMobile,
            tenant,
          },
        )

        if (experimentsForActivation.length === 0) {
          return
        }

        for (const key of experimentsForActivation) {
          experimentStatesRef.current[key] = 'pending-activation'
        }

        activatePendingExperimentsDebounced()
      },
      [activatePendingExperimentsDebounced, isMobile, tenant],
    )

  const value = useMemo(
    () => ({
      addExperimentKeysForActivation,
      experiments,
    }),
    [addExperimentKeysForActivation, experiments],
  )

  return (
    <ExperimentContext.Provider value={value}>
      <ExperimentsPageTypeProvider pageType={ExperimentPageType.Default}>
        {children}
      </ExperimentsPageTypeProvider>
    </ExperimentContext.Provider>
  )
}
