import { type FC, type ReactElement } from 'react'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { type ExperimentType } from '../model/Experiment.types'

import { experimentComponentCompileAndGetExperiment } from './experimentComponentCompiler'
import { experimentComponentGetMatchedVariant } from './experimentComponentVariantMatcher'
import { useExperimentsContext } from './ExperimentsContext'
import { type ExperimentVariantProps } from './ExperimentVariant'

type ExperimentProps = {
  children:
    | ReactElement<ExperimentVariantProps>
    | ReactElement<ExperimentVariantProps>[]
  names: ExperimentType['name'][]
}

/*
 * This component is used to implement Optimizely experiments. It wraps {@link ExperimentVariant} components
 * and renders only matched (or default) variant.
 */
export const Experiment: FC<ExperimentProps> = (props): ReactElement | null => {
  const { children, names } = props

  const { experiments } = useExperimentsContext()
  const { tenant } = useGlobalConfigContext()

  const experiment = experimentComponentCompileAndGetExperiment(
    names,
    experiments,
  )

  return experimentComponentGetMatchedVariant(experiment, children, tenant)
}
