import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Accordion, AccordionDetails, AccordionSummary } from 'base-ui'

import { useGlobalConfigContext } from '../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../image'
import { useAlternativeUrlsContext } from '../../../alternativeUrls/alternativeUrlsContext'

export const LanguageSwitcherAccordion: FC = () => {
  const { language } = useGlobalConfigContext()
  const alternateLanguages = useAlternativeUrlsContext()
  const supportedLanguages = Object.keys(alternateLanguages)

  if (supportedLanguages.length <= 1) {
    return null
  }

  return (
    <div className="group relative" role="menu">
      <Accordion className="rounded-none bg-transparent">
        <AccordionSummary className="bg-transparent hover:bg-transparent">
          <div className="flex items-center text-s">
            <FormattedMessage id="general.languageSwitch.language" />
            <NextImageWithFallback
              alt="language flag"
              className="mx-2"
              fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
              height={20}
              src={alternateLanguages[language].flag}
              width={30}
            />
            {alternateLanguages[language].translatedLanguage}
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <ul>
            {supportedLanguages
              .filter((tenantLanguage) => tenantLanguage !== language)
              .map((languageCode) => (
                <li className="text-s" key={languageCode}>
                  <a
                    className="flex w-full items-center rounded px-5 py-4 font-bold hover:bg-light-primary-medium hover:underline"
                    data-lang={languageCode}
                    href={alternateLanguages[languageCode].path}
                    role="menuitem"
                  >
                    <span className="invisible">
                      <FormattedMessage id="general.languageSwitch.language" />
                    </span>
                    <NextImageWithFallback
                      alt="language flag"
                      className="mx-2"
                      fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
                      height={20}
                      src={alternateLanguages[languageCode].flag}
                      width={30}
                    />
                    {alternateLanguages[languageCode].translatedLanguage}
                  </a>
                </li>
              ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
