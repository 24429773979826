import { clsx } from 'clsx'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconMenu } from '@redteclab/icons'

import { Button } from 'base-ui'

import {
  EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_HAMBURGER,
  experimentsTrackEvent,
} from '../../../../../../experiments/eventTracking'
import { useGetExperiment } from '../../../../../../experiments/hooks'
import { EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST } from '../../../../../../experiments/model/EXPERIMENT_NAME'
import { EXPERIMENT_VARIATION } from '../../../../../../experiments/model/EXPERIMENT_VARIATION'
import { useGlobalConfigContext } from '../../../../../../global-config'
import { useOffCanvasHeaderMenuContext } from '../../../OffCanvasHeaderMenuContext'

const OffCanvasHeaderMenuToggle: FC = () => {
  const offCanvasHeaderMenuContext = useOffCanvasHeaderMenuContext()
  const publicConfig = useGlobalConfigContext()

  const { variant } = useGetExperiment(EXPERIMENT_NAME_HEADER_MOBILE_ICON_LIST)

  return (
    <Button
      className="flex items-center bg-transparent pl-1 pr-4 text-dark-primary-max hover:bg-transparent"
      data-qa-id="m-hamburger-menu"
      onClick={() => {
        offCanvasHeaderMenuContext.openControls.handleMenuVisibilityToggle()
        experimentsTrackEvent(
          publicConfig,
          EXPERIMENTS_TRACK_EVENT_HEADER_MOBILE_HAMBURGER,
        )
      }}
      startIcon={
        <IconMenu
          className={clsx(
            variant === EXPERIMENT_VARIATION.V1 ? 'size-7' : 'text-3xl',
          )}
        />
      }
      type="button"
      variant="text"
    >
      <span className="sr-only">
        <FormattedMessage id="navigation.button.label.menu" />
      </span>
    </Button>
  )
}

export { OffCanvasHeaderMenuToggle }
