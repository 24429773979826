'use client'

import dynamic from 'next/dynamic'
import { useSearchParams } from 'next/navigation'
import { type JSX } from 'react'
import { useSessionStorage } from 'usehooks-ts'

import { useGlobalConfigContext } from '../../global-config/context/globalConfigContext'
import { SHOP_ENV } from '../../tenant/tenantTypes'
import { useExperimentsPageType } from '../components/ExperimentsPageTypeContext'
import { ExperimentPageType } from '../ExperimentPageType'
import { useGetExperimentForTenant } from '../hooks'
import { EXPERIMENT_NEWSLETTER_MODAL } from '../model/EXPERIMENT_NAME_PER_TENANT'

import { NEWSLETTER_MODAL_ID } from './NewsletterModal'

const NewsletterModal = dynamic(
  async () => import('./NewsletterModal').then((mod) => mod.NewsletterModal),
  {
    ssr: false,
  },
)

const BLACKLISTED_ADWORD_QUERY_PARAMS = new Set([
  'google/S_de-CH_Branded_Exact/Brand_exact',
  'google/S_de-CH_Branded/Gen_Shop-Apotheke',
  'google/S_fr-CH_Branded_Exact/Brand_exact',
])

const BLACKLISTED_PAGES = new Set<ExperimentPageType>([
  ExperimentPageType.Account,
  ExperimentPageType.Cart,
  ExperimentPageType.Checkout,
  ExperimentPageType.Login,
])

const isBlacklistedAdWord = (adWord: string | null): boolean => {
  return adWord ? BLACKLISTED_ADWORD_QUERY_PARAMS.has(adWord) : false
}

const isValidNewsletterParam = (param: string | null): boolean => {
  return ['ch', 'fr'].includes(param ?? '')
}

export const NewsletterModalExperiment = (): JSX.Element | null => {
  const experiment = useGetExperimentForTenant(EXPERIMENT_NEWSLETTER_MODAL)
  const [newsletterModalShown] = useSessionStorage(NEWSLETTER_MODAL_ID, false)
  const globalConfig = useGlobalConfigContext()
  const searchParams = useSearchParams()
  const pageType = useExperimentsPageType()

  const shouldShowModal =
    experiment.isEnabled &&
    !isBlacklistedAdWord(searchParams.get('adword')) &&
    !isValidNewsletterParam(searchParams.get('newsletter')) &&
    (globalConfig.shopEnv !== SHOP_ENV.QS ||
      searchParams.get('forceModalOnQs') === 'true') &&
    !BLACKLISTED_PAGES.has(pageType) &&
    !newsletterModalShown

  return shouldShowModal ? <NewsletterModal /> : null
}
