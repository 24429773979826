import { type FC } from 'react'

import { useActivateNeuralSearchExperiment } from '../../../../experiments/neural-search-experiment/useActivateNeuralSearchExperiment'
import { useDisableMobileBodyScroll } from '../../../../generic-hooks'
import { usePushGtmUserDataAvailableEvent } from '../../../../gtm'
import { useHeaderSearchContext } from '../../header-search/headerSearchContext'
import { HeaderDataContextProvider } from '../../headerConfigContext'
import { type HeaderDataType } from '../../model/headerTypes'
import { HeaderBanner } from '../HeaderBanner'
import { TopHeaderStickyBanner } from '../TopHeaderStickyBanner/TopHeaderStickyBanner'

import { HeaderBackdropContextProvider } from './headerBackdropContext'
import { HeaderDesktop } from './HeaderDesktop'
import { HeaderMobile } from './HeaderMobile'

interface HeaderProps {
  headerData: HeaderDataType
}

export const Header: FC<HeaderProps> = ({ headerData }) => {
  const headerSearchContext = useHeaderSearchContext()
  usePushGtmUserDataAvailableEvent()
  useActivateNeuralSearchExperiment()

  /**
   * We need to take portalResultsTo into consideration to cover an edge case since we use searchIsActive for both mobile and desktop
   * Edge case: open search on mobile, resize to desktop and open search there, resize back to mobile -> the body scroll is not working
   * because the searchIsActive is still true from the desktop version but so the portalResultsTo
   */
  useDisableMobileBodyScroll(
    headerSearchContext.searchIsActive && !headerSearchContext.portalResultsTo,
  )

  return (
    <>
      <HeaderBanner />
      <HeaderBackdropContextProvider>
        <HeaderDataContextProvider headerData={headerData}>
          <HeaderDesktop />
          <HeaderMobile />
          <TopHeaderStickyBanner />
        </HeaderDataContextProvider>
      </HeaderBackdropContextProvider>
    </>
  )
}
