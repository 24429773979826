import { type FC } from 'react'

import { AdServerBanner } from '../../../../ad-server'

export const HeaderBanner: FC = () => (
  <AdServerBanner
    bannerClasses="ad-server-top-header-banner rounded-none"
    bannerTags={['top-banner']}
    className="top-0 z-20 rounded-none"
    dimensions={{
      height: 100,
      width: 1920,
    }}
  />
)
