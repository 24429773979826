import { type FC } from 'react'

import { StaticImage } from '../../../../../image'
import { type FooterUspBoxItem } from '../../../config/PerTenantFooterConfiguration.type'

type FooterUspProps = {
  uspItems?: FooterUspBoxItem[]
}

export const FooterUsp: FC<FooterUspProps> = ({ uspItems }) => {
  if (!uspItems?.length) {
    return null
  }

  return (
    <div className="container py-3.5">
      <ul className="flex flex-col gap-4 rounded-3xl bg-light-brand-medium p-4 tablet:flex-row">
        {uspItems.map((item) => (
          <li className="flex flex-1 items-center gap-3" key={item.headline}>
            <StaticImage alt="" height={100} src={item.imageData} width={100} />
            <div>
              <h3
                className="break-words text-l text-dark-brand [hyphens:auto] tablet:text-xl"
                // eslint-disable-next-line react/no-danger -- headline contains superscript HTML markup, migration to Server Component could resolve this issue
                dangerouslySetInnerHTML={{ __html: item.headline }}
              />
              <p
                className="text-m text-dark-primary-strong tablet:text-l"
                // eslint-disable-next-line react/no-danger -- label contains superscript HTML markup, migration to Server Component could resolve this issue
                dangerouslySetInnerHTML={{ __html: item.label }}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
