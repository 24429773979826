import { clsx } from 'clsx'
import Image from 'next/image'
import { type FC } from 'react'

import { type CmsBlockMenuItemsInner } from '@redteclab/api/clients/content-management-system'

import { useExperimentalAddStaticFileHost } from '../../../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import { useGlobalConfigContext } from '../../../../../global-config'
import { StaticImage } from '../../../../../image'
import { TENANT } from '../../../../../tenant'
import {
  urlNormalizeUri,
  urlResolverNfcLandingPage,
} from '../../../../../url-handling'
import { useHeaderDataContext } from '../../../headerConfigContext'
import ePrescriptionBanner from '../../../images/e-prescription-banner.png'
import { headerCmsBlockMenuItemsInnerAccessorIsComponentMenu } from '../../../model/headerCmsBlockMenuItemsInnerAccessor'

import { HeaderCategoryColumn } from './HeaderCategoryColumn'

interface HeaderCategoriesListProps {
  categories: CmsBlockMenuItemsInner[]
  isVisible: boolean
}

export const HeaderCategoriesList: FC<HeaderCategoriesListProps> = ({
  categories,
  isVisible,
}) => {
  const config = useGlobalConfigContext()
  const headerData = useHeaderDataContext()
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()
  const { locale, tenant } = config
  const { redcareOwnBrandsBanner } = headerData

  return (
    <div
      aria-hidden={!isVisible}
      className={clsx(
        'absolute z-10 w-full max-w-5xl overflow-hidden rounded bg-light-primary-low',
        isVisible ? 'block' : 'hidden',
      )}
    >
      <div className="mx-auto flex bg-light-primary-low">
        {categories.map((columnData, index) => {
          // necessary to reduce correct type
          if (
            !headerCmsBlockMenuItemsInnerAccessorIsComponentMenu(columnData)
          ) {
            return null
          }

          const isFirstColumn = index === 0

          return (
            <HeaderCategoryColumn
              className={clsx(
                'flex flex-1 flex-col p-4',
                isFirstColumn && 'bg-light-brand-medium pr-16',
              )}
              columnData={columnData}
              isFirstColumn={isFirstColumn}
              key={columnData.title}
            />
          )
        })}
        <div className="py-4 pr-4">
          {tenant === TENANT.COM ? (
            <a href={urlResolverNfcLandingPage(config)}>
              <StaticImage
                alt=""
                height={292}
                src={ePrescriptionBanner}
                width={200}
              />
            </a>
          ) : (
            <a
              href={urlNormalizeUri({
                locale,
                uri: redcareOwnBrandsBanner.href,
              })}
            >
              <Image
                alt=""
                height={312}
                src={addStaticCdnHostToUrl(redcareOwnBrandsBanner.src)}
                unoptimized
                width={150}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
