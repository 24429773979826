import { FocusTrap } from '@mui/base'
import { type FC, useState } from 'react'

import { type CmsComponentMenu } from '@redteclab/api/clients/content-management-system'

import { useHeaderBackdropContext } from '../../Header/headerBackdropContext'

import { HeaderCategoriesList } from './HeaderCategoriesList'
import { HeaderCategoriesMenuIconButton } from './HeaderCategoriesMenuIconButton'

interface HeaderCategoriesMenuProps {
  categoriesData: CmsComponentMenu
}

const useMenuVisibilityControls = (): {
  handleHideMenu: () => void
  handleShowMenu: () => void
  isShown: boolean
} => {
  const [isShown, setIsShown] = useState(false)
  const { hideBackdrop, showBackdrop } = useHeaderBackdropContext()

  return {
    handleHideMenu: (): void => {
      setIsShown(false)
      hideBackdrop()
    },
    handleShowMenu: (): void => {
      setIsShown(true)
      showBackdrop()
    },
    isShown,
  }
}

const HeaderCategoriesMenu: FC<HeaderCategoriesMenuProps> = ({
  categoriesData,
}) => {
  const menuVisibility = useMenuVisibilityControls()
  const { handleHideMenu, handleShowMenu, isShown } = menuVisibility

  const toggleMenu = (): void => {
    if (isShown) {
      handleHideMenu()
    } else {
      handleShowMenu()
    }
  }

  return (
    <FocusTrap open={isShown}>
      <div
        className="my-1 ml-1"
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            handleHideMenu()
          }
        }}
        onMouseEnter={handleShowMenu}
        onMouseLeave={handleHideMenu}
        role="menubar"
        tabIndex={-1}
      >
        <HeaderCategoriesMenuIconButton
          icon={categoriesData.icon}
          menuIsShown={isShown}
          onToggleMenu={toggleMenu}
          title={categoriesData.title}
        />
        <HeaderCategoriesList
          categories={categoriesData.items ?? []}
          isVisible={isShown}
        />
      </div>
    </FocusTrap>
  )
}

export { HeaderCategoriesMenu }
