import { type FC } from 'react'

import { useHeaderDataContext } from '../../headerConfigContext'
import { headerCmsBlockMenuAccessorGetNavigationMenuData } from '../../model/headerCmsBlockMenuAccessor'

import { HeaderNavigation } from './HeaderNavigation'

export const HeaderBottomSection: FC = () => {
  const { cmsMenu } = useHeaderDataContext()

  return (
    <div className="relative z-20 hidden bg-light-primary-low tablet:flex">
      <div className="mt-3 w-full bg-light-brand-high">
        <HeaderNavigation
          menuItems={headerCmsBlockMenuAccessorGetNavigationMenuData(cmsMenu)}
        />
      </div>
    </div>
  )
}
