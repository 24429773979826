'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useEffect,
} from 'react'

import { type ExperimentPageType } from '../ExperimentPageType'
import { experimentsPerPage } from '../model/experimentsPerPage'

import { useExperimentsContext } from './ExperimentsContext'

const ExperimentsPageTypeContext = createContext<
  ExperimentPageType | undefined
>(undefined)

export const useExperimentsPageType = (): ExperimentPageType => {
  const context = useContext(ExperimentsPageTypeContext)

  if (!context) {
    throw new Error('Wrap the component in <ExperimentsPageTypeProvider />')
  }

  return context
}

type ExperimentsPageTypeProviderProps = PropsWithChildren<{
  pageType: ExperimentPageType
}>

/**
 * Provides `pageType` for children components conditional logic and activates
 * page specific experiments.
 */
export const ExperimentsPageTypeProvider: FC<
  ExperimentsPageTypeProviderProps
> = ({ children, pageType }) => {
  const { addExperimentKeysForActivation } = useExperimentsContext()

  const experimentKeys = Object.entries(experimentsPerPage)
    .find(([key]) => key === pageType)
    ?.at(1)

  useEffect(() => {
    if (experimentKeys) {
      addExperimentKeysForActivation(experimentKeys)
    }
  }, [addExperimentKeysForActivation, experimentKeys])

  return (
    <ExperimentsPageTypeContext value={pageType}>
      {children}
    </ExperimentsPageTypeContext>
  )
}
