import {
  type FC,
  type ReactEventHandler,
  type RefObject,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
} from 'react'
import { useWindowSize } from 'usehooks-ts'

const resizeIframe = (iframe: HTMLIFrameElement): void => {
  let height: number | null = null

  try {
    height = iframe.contentWindow?.document.body.scrollHeight ?? null
  } catch {
    height = null
  }

  if (height === null) {
    return
  }

  iframe.style.height = `${height}px`
}

const useResizeIframeOnWindowResize = (
  iframeRef: RefObject<HTMLIFrameElement | null>,
): void => {
  const height = useDeferredValue(useWindowSize().height)

  useEffect(() => {
    if (iframeRef.current && height) {
      resizeIframe(iframeRef.current)
    }
  }, [height, iframeRef])
}

const forceLinksNavigateParentWindow = (iframe: HTMLIFrameElement): void => {
  const links = iframe.contentWindow?.document.querySelectorAll('a')

  if (!links?.length) {
    return
  }

  for (const link of links) {
    link.setAttribute('target', '_blank')
  }
}

export const FooterLegalTextIframe: FC<{
  legalTextIframeSrc: string
}> = ({ legalTextIframeSrc }) => {
  const ref = useRef<HTMLIFrameElement>(null)

  useResizeIframeOnWindowResize(ref)

  const onLoad: ReactEventHandler<HTMLIFrameElement> = useCallback(
    (event): void => {
      const iframe = event.currentTarget

      ref.current = iframe

      const { contentWindow } = iframe

      if (contentWindow) {
        contentWindow.document.body.style.overflow = 'hidden'
      }

      forceLinksNavigateParentWindow(iframe)

      resizeIframe(iframe)
    },
    [],
  )

  return (
    <iframe
      className="max-w-5xl"
      onLoad={onLoad}
      sandbox="allow-same-origin allow-top-navigation allow-popups"
      src={legalTextIframeSrc}
      title="legalText"
      width="100%"
    />
  )
}
