import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { SocialMediaImage } from '../../../../../../social-media'

import { type FooterSocialProps } from './FooterSocialProps'

export const FooterSocial: FC<FooterSocialProps> = ({ socialLinksItems }) => {
  if (socialLinksItems.length === 0) {
    return null
  }

  return (
    <div className="flex items-center gap-3.5">
      <h3 className="hidden text-s font-medium tablet:block">
        <FormattedMessage id="footer.headline.followUs" />
      </h3>
      <ul className="flex items-center gap-2">
        {socialLinksItems.map(({ href, id }) => (
          <li className="shrink-0" key={id}>
            <a href={href} rel="noreferrer" target="_blank">
              <SocialMediaImage iconName={id} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}
