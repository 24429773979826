import { type FC } from 'react'

import { AccordionStack } from 'base-ui'

import {
  type FooterInfoSectionCategory,
  type FooterPaymentPartner,
  type FooterShippingPartner,
} from '../../../config/PerTenantFooterConfiguration.type'

import { FooterInfoSection } from './FooterInfoSection'
import { FooterNewsletter } from './FooterNewsletter'
import { FooterPaymentPartners } from './FooterPaymentPartners'
import { FooterShippingPartners } from './FooterShippingPartners'

type FooterBannerSectionProps = {
  infoSectionItems: FooterInfoSectionCategory[]
  newsletterDiscount: string
  paymentPartnersItems: FooterPaymentPartner[]
  shippingPartnersItems: FooterShippingPartner[]
}

export const FooterBannerSection: FC<FooterBannerSectionProps> = ({
  infoSectionItems,
  newsletterDiscount,
  paymentPartnersItems,
  shippingPartnersItems,
}) => (
  <div className="tablet:bg-light-brand-medium">
    <AccordionStack className="container py-2.5 tablet:grid tablet:grid-cols-5 tablet:gap-x-4 tablet:gap-y-8 tablet:py-8">
      <FooterShippingPartners
        className="col-span-2"
        shippingPartnersItems={shippingPartnersItems}
      />
      <FooterPaymentPartners
        className="col-span-2 col-start-4"
        paymentPartnersItems={paymentPartnersItems}
      />
      {infoSectionItems.map((category) => (
        <FooterInfoSection category={category} key={category.title} />
      ))}
      <FooterNewsletter
        className="col-span-2"
        newsletterDiscount={newsletterDiscount}
      />
    </AccordionStack>
  </div>
)
