import { type FC } from 'react'

import { useExperimentsContext } from '../../../experiments/components/ExperimentsContext'
import { experimentAccessorIsAdServerEnabled } from '../../../experiments/model/experimentAccessor'

import {
  type AdBannerProps,
  AdServerBannerContent,
} from './AdServerBannerContent'

export const AdServerBanner: FC<AdBannerProps> = (props) => {
  const { experiments } = useExperimentsContext()
  const isAdServerEnabled = experimentAccessorIsAdServerEnabled(experiments)

  if (!isAdServerEnabled) {
    return null
  }

  return <AdServerBannerContent {...props} />
}
