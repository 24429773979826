import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { useGlobalConfigContext } from '../../../../../../global-config'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  NextImageWithFallback,
} from '../../../../../../image'
import {
  type assetsCountryFlagLogoMap,
  assetUrlGetCountryFlag,
} from '../../../../../../url-handling'

import { type FooterShopsProps } from './FooterShopsProps'

export const FooterShops: FC<FooterShopsProps> = ({ tenantLinksItems }) => {
  const { assetsPrefix } = useGlobalConfigContext()

  const getCountryLabel = (
    country: keyof typeof assetsCountryFlagLogoMap,
  ): JSX.Element | null => {
    switch (country) {
      case 'austria': {
        return <FormattedMessage id="footer.flag.austria.label" />
      }
      case 'belgium': {
        return <FormattedMessage id="footer.flag.belgium.label" />
      }
      case 'france': {
        return <FormattedMessage id="footer.flag.france.label" />
      }
      case 'germany': {
        return <FormattedMessage id="footer.flag.germany.label" />
      }
      case 'italy': {
        return <FormattedMessage id="footer.flag.italy.label" />
      }
      case 'switzerland': {
        return <FormattedMessage id="footer.flag.switzerland.label" />
      }

      default: {
        return null
      }
    }
  }

  return (
    <ul className="flex shrink-0 flex-wrap justify-center gap-3 tablet:flex-nowrap tablet:justify-end">
      {tenantLinksItems.map(({ alt, href, id }) => (
        <li key={id}>
          <a className="group inline-flex items-center space-x-2" href={href}>
            <NextImageWithFallback
              alt={alt}
              fallbackSrc={IMAGE_PRODUCT_FALLBACK_SRC}
              height={20}
              src={assetUrlGetCountryFlag({
                assetsPrefix,
                country: id as keyof typeof assetsCountryFlagLogoMap,
              })}
              width={30}
            />
            <span className="text-s">
              {getCountryLabel(id as keyof typeof assetsCountryFlagLogoMap)}
            </span>
          </a>
        </li>
      ))}
    </ul>
  )
}
